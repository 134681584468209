import React, { useState, useEffect } from 'react';

const TimerComponent = ({ estimatedTime, onComplete }) => {
    const [timeLeft, setTimeLeft] = useState(estimatedTime);

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft(prev => {
                if (prev <= 1) {
                    clearInterval(timer);
                    onComplete();
                    return 0;
                }
                return prev - 1;
            });
        }, 1000);

        return () => clearInterval(timer);
    }, [onComplete]);

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${minutes}:${secs.toString().padStart(2, '0')}`;
    };

    return (
        <div style={{
            backgroundColor: '#FFF7E6',  // Soft background color for contrast
            color: '#E50A7A',  // Pink text color for branding
            border: '2px solid #E50A7A',  // Border with pink color
            padding: '10px 20px',
            borderRadius: '12px',  // Rounded corners for a modern look
            fontSize: '1.4rem',  // Increase font size
            fontWeight: '700',  // Bold for emphasis
            textAlign: 'center',
            maxWidth: '300px',
            margin: '0 auto',
            fontFamily: "'Montserrat', sans-serif",  // Modern and sleek font
        }}>
            <span style={{
                fontSize: '1.6rem',  // Larger font size for the numbers
                color: '#CDA55F'  // Golden color for the numbers to stand out
            }}>
                {formatTime(timeLeft)}
            </span>
        </div>
    );
};

export default TimerComponent;

