// components/LabCard.js
import React from 'react';
import { Card, Button, Row, Col } from 'react-bootstrap';

const LabCard = ({ title, description, buttonText, handleClick, isActive }) => (
  <Row>
    <Col>
      <Card style={{
        border: 'none',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        backgroundColor: isActive ? '#FFFFFF' : '#F0F0F0',  // Lighter shade for 'Coming Soon'
        opacity: isActive ? '1' : '0.7'  // Slight transparency for 'Coming Soon'
      }}>
        <Card.Body>
          <Card.Title style={{ color: '#E50A7A' }}>{title}</Card.Title>
          <Card.Text>{description}</Card.Text>
          <Button
            onClick={handleClick}
            disabled={!isActive}  // Disable the button if the lab is not active
            style={{
              backgroundColor: isActive ? '#E50A7A' : '#C0C0C0',  // Grey out the button for 'Coming Soon'
              borderColor: isActive ? '#E50A7A' : '#C0C0C0',
              color: '#FFFFFF',
              width: '50%',
            }}
            onMouseEnter={(e) => {
              if (isActive) e.currentTarget.style.backgroundColor = '#C00766';
            }}
            onMouseLeave={(e) => {
              if (isActive) e.currentTarget.style.backgroundColor = '#E50A7A';
            }}
          >
            {isActive ? buttonText : 'Coming Soon'}
          </Button>
        </Card.Body>
      </Card>
    </Col>
  </Row>
);

export default LabCard;
