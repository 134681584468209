import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';  // Correctly import jwtDecode
import axios from 'axios';  // For calling the Check Active Lab API
import { Container, Row, Col, Button, Card, Image, Modal, Tabs, Tab } from 'react-bootstrap';
import LUITLogo from './assets/LUIT_logo.png'; 
import ChallengeLabs from './ChallengeLabs';
import AssessmentLabs from './AssessmentLabs';
import PodLabs from './PodLabs';
import clearLabSessionData from './sessionUtils';  // Import clearLabSessionData from sessionUtils

const HomePage = () => {
  const [userData, setUserData] = useState(null);
  const [showModal, setShowModal] = useState(false);  // State for modal
  const [activeLabData, setActiveLabData] = useState(null);  // Store active lab details
  const [selectedLabType, setSelectedLabType] = useState('challenge');  // Default to Challenge Labs
  const navigate = useNavigate();

  useEffect(() => {
    const idToken = localStorage.getItem("idToken");

    if (idToken) {
      // Ensure token is properly formatted before decoding
      if (idToken.split('.').length === 3) {
        try {
          const decodedToken = jwtDecode(idToken);  // Decode the id_token
          setUserData(decodedToken);
          checkActiveLab(decodedToken.sub);  // Check for active lab after successful authentication
        } catch (error) {
          console.error("Error decoding token:", error);
          localStorage.removeItem("idToken");  // Clear invalid token
          navigate("/");  // Redirect to login if token is invalid
        }
      } else {
        console.error("Invalid token format:", idToken);
        localStorage.removeItem("idToken");  // Clear invalid token
        navigate("/");  // Redirect to login if token is malformed
      }
    } else {
      navigate("/");  // Redirect to login if not authenticated
    }
  }, [navigate]);

  // Function to check for an active lab
  const checkActiveLab = async (userSessionId) => {
    const idToken = localStorage.getItem("idToken");

    try {
      const response = await axios.get(`${process.env.REACT_APP_CHECK_ACTIVE_LAB_API}`, {
        params: { UserSessionId: userSessionId },
        headers: { Authorization: `Bearer ${idToken}` },
      });

      console.log("Active Lab Response:", response.data);  // Log the data received from the API

      if (response.data.activeLab) {
        setActiveLabData(response.data);  // Store active lab data
        setShowModal(true);  // Show the modal to continue the lab

        // Save the session data in sessionStorage for later use
        sessionStorage.setItem('labName', response.data.labName);
        sessionStorage.setItem('lab', response.data.lab);
        sessionStorage.setItem('labTitle', response.data.labTitle);
        sessionStorage.setItem('username', response.data.username);
        sessionStorage.setItem('password', response.data.password);
        sessionStorage.setItem('consoleUrl', response.data.consoleUrl);
        sessionStorage.setItem('companyWebsite', response.data.companyWebsite);
        sessionStorage.setItem('endTime', response.data.endTime.toString());  // Store endTime as string
        sessionStorage.setItem('resourceNames', JSON.stringify(response.data.resourceNames));

        console.log("Session data stored in sessionStorage:", response.data);
      } else {
        console.log('No active lab found.');
        clearLabSessionData();  // Clear session data when no active lab is found
      }
    } catch (error) {
      console.error("Error checking active lab:", error);
      clearLabSessionData();  // Also clear session data in case of an error
    }
  };

  const handleContinueLab = () => {
    const labSessionData = activeLabData;  // Use the active lab data
    console.log("Lab Session Data for Navigation:", labSessionData);  // Log the data before navigation
  
    if (labSessionData) {
      // Navigate to DeploymentPage with the saved session details
      navigate('/deploy', {
        state: {
          lab: labSessionData.lab,
          labName: labSessionData.labName,
          title: labSessionData.labTitle,
          consoleUrl: labSessionData.consoleUrl,
          username: labSessionData.username,
          password: labSessionData.password,
          companyWebsite: labSessionData.companyWebsite,
          endTime: labSessionData.endTime  // Pass EndTime to DeploymentPage
        }
      });
    }
  };

  const handleModalClose = () => {
    setShowModal(false);
    setActiveLabData(null);
  };

  const handleStartLab = async (labType, labName, labTitle, estimatedTime) => {
    const idToken = localStorage.getItem("idToken");
    const userSessionId = localStorage.getItem("userSessionId"); // Assume this is stored when the user logs in
  
    try {
      // Check if there is an active lab first
      const response = await axios.get(`${process.env.REACT_APP_CHECK_ACTIVE_LAB_API}`, {
        params: { UserSessionId: userSessionId },
        headers: { Authorization: `Bearer ${idToken}` },
      });
  
      console.log("Active Lab Response:", response.data);  // Log the data received from the API
  
      if (response.data.activeLab) {
        // If an active lab is found, show the modal and let the user decide
        setActiveLabData(response.data);  // Store active lab data
        setShowModal(true);  // Show the modal to continue the lab
  
        // Save the session data in sessionStorage for later use
        sessionStorage.setItem('labName', response.data.labName);
        sessionStorage.setItem('lab', response.data.lab);
        sessionStorage.setItem('labTitle', response.data.labTitle);
        sessionStorage.setItem('username', response.data.username);
        sessionStorage.setItem('password', response.data.password);
        sessionStorage.setItem('consoleUrl', response.data.consoleUrl);
        sessionStorage.setItem('companyWebsite', response.data.companyWebsite);
        sessionStorage.setItem('endTime', response.data.endTime.toString());
        sessionStorage.setItem('resourceNames', JSON.stringify(response.data.resourceNames));
  
      } else {
        // No active lab found, proceed with starting a new lab and clear old session data
        clearLabSessionData();
        navigate('/deploy', { state: { lab: labType, labName, title: labTitle, estimatedTime } });
      }
    } catch (error) {
      console.error("Error checking active lab:", error);
      clearLabSessionData();  // Clear session data in case of an error
      navigate('/deploy', { state: { lab: labType, labName, title: labTitle, estimatedTime } });
    }
  };

  return (
    <Container style={{ marginTop: '5rem', backgroundColor: '#F4F4F9', color: '#1A1B54' }}>
      {/* Modal for continuing active lab */}
      <Modal show={showModal} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Active Lab Found</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          You have an ongoing lab session. Do you want to continue where you left off?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleContinueLab}>Continue Lab</Button>
        </Modal.Footer>
      </Modal>

      <Row className="justify-content-center">
        <Col md={8} className="text-center">
          <Image src={LUITLogo} style={{ width: '150px', marginBottom: '1rem' }} alt="Level Up In Tech Logo" />
          <Card style={{ textAlign: 'center', border: 'none', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
            <Card.Header style={{ backgroundColor: '#1A1B54', color: '#FFFFFF' }}>
              <h3 style={{ color: '#E50A7A' }}><strong>Welcome to Level Up In Tech Labs</strong></h3>
            </Card.Header>
            <Card.Body style={{ backgroundColor: '#FFFFFF' }}>
              <Tabs
                activeKey={selectedLabType}
                onSelect={(tabKey) => setSelectedLabType(tabKey)}
                className="mb-4"
              >
                <Tab eventKey="challenge" title="Challenge Labs">
                  <ChallengeLabs handleStartLab={handleStartLab} />
                </Tab>
                <Tab eventKey="assessment" title="Assessment Labs">
                  <AssessmentLabs handleStartLab={handleStartLab} />
                </Tab>
                <Tab eventKey="pod" title="Pod Collaboration">
                  <PodLabs />
                </Tab>
              </Tabs>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default HomePage;
