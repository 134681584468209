// components/ChallengeLabs.js
import React from 'react';
import LabCard from './LabCard';

const ChallengeLabs = ({ handleStartLab }) => (
  <>
    <LabCard
      title="Static Website in S3"
      description="Troubleshoot issues with a static website hosted on Amazon S3."
      buttonText="Start Challenge"
      handleClick={() => handleStartLab('challenge', 's3-static-website', 'Static Website in S3', 60)}
      isActive={true} 
    />
    <LabCard
      title="Nginx Challenge Lab"
      description="Configure an Nginx web server and troubleshoot misconfigurations in the S3 bucket."
      buttonText="Start Challenge"
      handleClick={() => handleStartLab('challenge', 'nginx-challenge', 'Nginx Challenge Lab', 165)}
      isActive={true} 
    />
    <LabCard
      title="VPC Networking Lab"
      description="Fix misconfigurations in the VPC setup, including subnets and security groups, to ensure proper networking."
      buttonText="Start Challenge"
      handleClick={() => handleStartLab('challenge', 'vpc-networking', 'VPC Networking Lab', 60)}
      isActive={true} 
    />
    <LabCard
      title="ALB Challenge Lab"
      description="Fix the misconfigurations in the AWS environment and make the site accessible through the Application Load Balancer."
      buttonText="Start Challenge"
      handleClick={() => handleStartLab('challenge', 'ALB-Challenge', 'ALB Challenge Lab', 180)}
      isActive={true} 
    />
  </>
);

export default ChallengeLabs;
