// components/PodLabs.js
import React, { useState } from 'react';
import LabCard from './LabCard';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Modal, Button } from 'react-bootstrap';

const PodLabs = () => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');

  const handleEnterMilestone = async (milestone, milestoneTitle) => {
    const idToken = localStorage.getItem('idToken');
    const podName = localStorage.getItem('podName');
    const username = localStorage.getItem('username');

    if (!idToken || !podName || !username) {
      setModalMessage('Authentication information is missing. Please sign in again.');
      setShowModal(true);
      return;
    }

    console.log('Attempting to fetch user data with the following parameters:');
    console.log(`idToken: ${idToken}`);
    console.log(`podName: ${podName}`);
    console.log(`username: ${username}`);

    try {
      const response = await axios.get(
        process.env.REACT_APP_CHECK_POD_USERS_API,
        {
          headers: {
            Authorization: `Bearer ${idToken}`
          },
          params: {
            podName: podName,
            username: username
          }
        }
      );

      console.log('API response received:', response);

      if (response.data && response.data.username) {
        console.log('User data found, navigating to PodPage');
        const podData = {
          milestone,
          milestoneTitle,
          consoleUrl: response.data.consoleUrl,
          username: response.data.username,
          password: response.data.password,
          trelloLink: response.data.trelloLink,
          useCaseDescription: 'This is a placeholder description for the milestone project use case.',
          diagram: 'Diagram will be displayed here'
        };
        navigate('/pods', { state: { podData } });
      } else {
        setModalMessage('You are not assigned to a pod at the moment. Please contact your administrator for further assistance.');
        setShowModal(true);
      }
    } catch (error) {
      console.error('Error fetching pod user data:', error);

      if (error.response) {
        console.error('Error response data:', error.response.data);
        console.error('Error response status:', error.response.status);
      }

      setModalMessage('No user found for this Pod Collaboration project. If you feel this is in error please contact your Coach.');
      setShowModal(true);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <LabCard
        title="Milestone 1"
        description="Deploy Website with Dynamic Image"
        buttonText="Enter Milestone"
        handleClick={() => handleEnterMilestone('1', 'Milestone 1 - Hop Haven Brewery\'s Dynamic Beverage Website')}
        isActive={true}  // Mark as active if ready to start
      />
      <LabCard
        title="Milestone 2"
        description="Deploy Serverless Architecture"
        buttonText="Enter Milestone"
        handleClick={() => handleEnterMilestone('2', 'Milestone 2 - Deploying Serverles DevOps Architecture')}
        isActive={true}  // Set to false if the lab is coming soon
      />
      <LabCard
        title="Milestone 3"
        description="Deploy CI/CD Environment with Terraform"
        buttonText="Enter Milestone"
        handleClick={() => handleEnterMilestone('3', 'Milestone 3')}
        isActive={false}  // Set to false if the lab is coming soon
      />

      {/* Modal for user notifications */}
      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Notification</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalMessage}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PodLabs;
