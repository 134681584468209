// components/AssessmentLabs.js
import React from 'react';
import LabCard from './LabCard';

const AssessmentLabs = ({ handleStartLab }) => (
  <>
    <LabCard
      title="S3 Static Website Assessment"
      description="Build an S3 Static Website based on given specifications and make the website publicly accessible."
      buttonText="Start Assessment"
      handleClick={() => handleStartLab('assessment', 's3-website-assessment', 'S3 Static Website Assessment', 30)}
      isActive={true} 
    />
    <LabCard
      title="SSH Assessment"
      description="Deploy an EC2 instance and SSH remotely."
      buttonText="Start Assessment"
      handleClick={() => handleStartLab('assessment', 'ssh-assessment', 'SSH Assessment')}
      isActive={false}  // Set false if the lab is coming soon
    />
  </>
);

export default AssessmentLabs;
