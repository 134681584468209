// sessionUtils.js

const clearLabSessionData = () => {
    sessionStorage.removeItem('labName');
    sessionStorage.removeItem('lab');
    sessionStorage.removeItem('labTitle');
    sessionStorage.removeItem('username');
    sessionStorage.removeItem('password');
    sessionStorage.removeItem('consoleUrl');
    sessionStorage.removeItem('companyWebsite');
    sessionStorage.removeItem('endTime');
    sessionStorage.removeItem('resourceNames');
    sessionStorage.removeItem('deploymentStatus');
    sessionStorage.removeItem('validationStatus');
    sessionStorage.removeItem('isPolling');
    sessionStorage.removeItem('stackName');
    sessionStorage.removeItem('pollingSessionId');  
    console.log("Lab session data cleared.");
};

export default clearLabSessionData;
