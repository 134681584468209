import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Button, Spinner, Image, Card } from 'react-bootstrap';
import { jwtDecode } from 'jwt-decode'; // Import for decoding JWT tokens
import LUITLogo from './assets/LUIT_logo.png';

const UserSelectionPage = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);  // Error state
  const navigate = useNavigate();

  // Cognito Sign-in Handler for Implicit Grant Flow
  const handleCognitoSignIn = () => {
    const domain = process.env.REACT_APP_COGNITO_DOMAIN;
    const clientId = process.env.REACT_APP_COGNITO_CLIENT_ID;
    const redirectUri = process.env.REACT_APP_COGNITO_REDIRECT_URI;

    // Use response_type=token for implicit grant
    const loginUrl = `https://${domain}/login?client_id=${clientId}&response_type=token&scope=email+openid+profile&redirect_uri=${redirectUri}`;
    window.location.href = loginUrl;
  };

  // Handle id_token received after implicit grant flow
  // Handle id_token received after implicit grant flow
const handleIdToken = (idToken) => {
  try {
    const decodedIdToken = jwtDecode(idToken);  // Decode the id_token
    const userSessionId = decodedIdToken.sub;   // Extract 'sub' for user session ID
    const username = decodedIdToken['cognito:username'] || decodedIdToken['preferred_username']; // Extract username
    const podName = decodedIdToken['cognito:groups'] ? decodedIdToken['cognito:groups'][0] : null; // Extract group (PodName)

    // Store the id_token, user session, username, and podName in localStorage
    localStorage.setItem('idToken', idToken);
    localStorage.setItem('userSessionId', userSessionId);
    localStorage.setItem('username', username);
    if (podName) {
      localStorage.setItem('podName', podName);
    }

    console.log('UserSessionId:', userSessionId);
    console.log('Username:', username);
    console.log('PodName:', podName);

    setIsAuthenticated(true);
    setLoading(false);
    navigate('/home');  // Redirect to home after successful authentication
  } catch (error) {
    console.error('Error handling id_token:', error);
    setErrorMessage('Failed to authenticate. Please try again.');
    setIsAuthenticated(false);
    setLoading(false);
  }
};


  // Effect to capture id_token from URL after redirect
  useEffect(() => {
    const hashParams = new URLSearchParams(window.location.hash.substring(1));
    const idToken = hashParams.get('id_token');

    if (idToken) {
      handleIdToken(idToken);  // Process id_token if found in URL
    } else {
      setIsAuthenticated(false);
      setLoading(false);
    }
  }, [navigate]);

  return (
    <Container
      style={{
        marginTop: '5rem',
        maxWidth: '500px',
        backgroundColor: '#F4F4F9',
        color: '#1A1B54',
        padding: '2rem',
        borderRadius: '8px',
        textAlign: 'center',
      }}
    >
      <Image
        src={LUITLogo}
        style={{ width: '150px', marginBottom: '1rem' }}
        alt="Level Up In Tech Logo"
      />
      <Card style={{ textAlign: 'center', border: 'none', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
        <Card.Body>
          <h2 style={{ color: '#1A1B54' }}>Level Up In Tech Labs</h2>
          {loading ? (
            <Spinner animation="border" />
          ) : isAuthenticated ? (
            <div>
              <h4>Authenticated via Cognito</h4>
              <Button
                variant="primary"
                onClick={() => navigate('/home')}
                style={{
                  backgroundColor: '#E50A7A',
                  borderColor: '#E50A7A',
                  color: '#FFFFFF',
                  width: '100%',
                  marginTop: '1rem',
                }}
                onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#C00766')}
                onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = '#E50A7A')}
              >
                Continue to Home
              </Button>
            </div>
          ) : (
            <>
              {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
              <Button
                variant="secondary"
                onClick={handleCognitoSignIn}
                style={{
                  width: '100%',
                  marginTop: '1rem',
                  backgroundColor: '#E50A7A',
                  borderColor: '#E50A7A',
                  color: '#FFFFFF',
                }}
                onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#C00766')}
                onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = '#E50A7A')}
              >
                Sign in with Cognito
              </Button>
            </>
          )}
        </Card.Body>
      </Card>
    </Container>
  );
};

export default UserSelectionPage;
