// components/PodPage.js
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Container, Row, Col, Button, Alert, Image } from 'react-bootstrap';
import LUITLogo from './assets/LUIT_logo.png';
import { Clipboard } from 'react-bootstrap-icons';
import axios from 'axios';

const PodPage = () => {
    const location = useLocation();
    const { podData } = location.state || {};
    const milestone = podData?.milestone || '1'; // Default to milestone 1 if not provided

    const [useCaseContent, setUseCaseContent] = useState('Loading use case...');
    const [diagramImage, setDiagramImage] = useState('');

    const handleCopy = (text) => {
        navigator.clipboard.writeText(text).then(() => {
            alert('Copied to clipboard!');
        });
    };

    useEffect(() => {
        // Fetch the Use Case content
        axios.get(`${process.env.REACT_APP_S3_USE_CASE_BUCKET}/milestone${milestone}.html`)
            .then(response => {
                setUseCaseContent(response.data);
            })
            .catch(error => {
                setUseCaseContent('Error loading use case content.');
                console.error('Error fetching use case:', error);
            });

        // Set the Diagram URL
        setDiagramImage(`${process.env.REACT_APP_S3_DIAGRAM_BUCKET}/milestone${milestone}.png`);
    }, [milestone]);

    return (
        <Container style={{ marginTop: '5rem', background: 'linear-gradient(135deg, #ffffff, #f4f4f9)', padding: '2rem', borderRadius: '8px' }}>
            <Image src={LUITLogo} onClick={() => window.location.href = '/home'} style={{ cursor: 'pointer', position: 'absolute', top: '20px', left: '20px', width: '100px', height: 'auto' }} />
            
            <h2 style={{ color: '#1A1B54', textAlign: 'center', marginBottom: '2rem' }}>{`${podData?.milestoneTitle || "Milestone"}`}</h2>
            
            <Row>
                <Col md={6}>
                    <Alert style={{ background: 'linear-gradient(135deg, #3a539b, #1A1B54)', borderColor: '#2C3E50', color: '#FFFFFF', padding: '20px', borderRadius: '8px' }}>
                        <h4 style={{ fontSize: '1.5rem', fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>
                            Project Credentials
                        </h4>
                        <p><strong>Username:</strong> {podData?.username || 'N/A'}
                            <Clipboard
                                role="button"
                                onClick={() => handleCopy(podData?.username || 'N/A')}
                                style={{ cursor: 'pointer', marginLeft: '8px', color: '#FFFFFF' }}
                            />
                        </p>
                        <p><strong>Password:</strong> {podData?.password || 'N/A'}
                            <Clipboard
                                role="button"
                                onClick={() => handleCopy(podData?.password || 'N/A')}
                                style={{ cursor: 'pointer', marginLeft: '8px', color: '#FFFFFF' }}
                            />
                        </p>
                        <p>
                            <strong>AWS Console URL:</strong>{' '}
                            <a href={podData?.consoleUrl || '#'} target="_blank" rel="noopener noreferrer" style={{ color: '#FFFFFF', textDecoration: 'underline' }}>
                                {podData?.consoleUrl || 'URL not available'}
                            </a>
                        </p>
                        <p>
                            <strong>Trello Link:</strong>{' '}
                            <a href={podData?.trelloLink || '#'} target="_blank" rel="noopener noreferrer" style={{ color: '#FFFFFF', textDecoration: 'underline' }}>
                                Project Board
                            </a>
                        </p>
                    </Alert>

                    {/* Hide the validate button using inline style until functionality built in*/}
                    <Button
                        variant="primary"
                        style={{ backgroundColor: '#E50A7A', borderColor: '#E50A7A', width: '100%', marginTop: '1rem', display: 'none' }}
                    >
                        Validate Project
                    </Button>
                </Col>

                <Col md={6}>
                    {/* Hide the validate section using inline style until functionality built in*/}
                    <Alert style={{ backgroundColor: '#F4F4F9', borderColor: '#E50A7A', color: '#1A1B54', display: 'none' }} variant="info">
                        <h4>Validation</h4>
                        <p>Validation section will be added later.</p>
                    </Alert>
                </Col>
            </Row>

            <Row className="mt-4">
                <Col md={6}>
                    <Alert style={{ backgroundColor: '#F4F4F9', borderColor: '#E50A7A', color: '#1A1B54' }} variant="info">
                        <h4>Project Use Case</h4>
                        <div dangerouslySetInnerHTML={{ __html: useCaseContent }} /> {/* Render HTML content */}
                    </Alert>
                </Col>

                <Col md={6}>
                    <Alert style={{ backgroundColor: '#F4F4F9', borderColor: '#E50A7A', color: '#1A1B54' }} variant="info">
                        <h4>Project Diagram</h4>
                        {diagramImage ? (
                            <img src={diagramImage} alt="Project Diagram" style={{ width: '100%', height: 'auto' }} />
                        ) : (
                            <p>Diagram will be displayed here</p>
                        )}
                    </Alert>
                </Col>
            </Row>
        </Container>
    );
};

export default PodPage;
